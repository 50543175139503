<template>
  <v-dialog v-model="showDialog" persistent scrollable max-width="600">
    <v-card>
      <v-card-title class="pa-0">
        <v-icon class="pa-2" @click="closeDialog">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text>
        <v-container class="pa-0 ma-0 mx-0 transparent" fluid style="z-index: 1000">
          <v-row class="mx-0 ma-0 px-0 pa-0 pt-3" no-gutters>
            <v-col
                v-if="parseInt(Number(settings.settings.ReturnToPosAfter))!==0 && settings.settings.hasOwnProperty('ReturnToPosAfter')"
                class="pr-2" cols="6">
          <span class="text-muted font-size-xlg font-weight-lighter">
            {{$t('generic.lang_nextCustomerAfter')}}:
          </span>

              <span class="font-weight-bolder mx-auto text-color fsize-2" width="100%">
                {{ this.countDown }}
              </span>
            </v-col>

            <v-col class="text-right pr-2">
          <span class="text-muted font-size-xlg font-weight-lighter">
            {{ $t('generic.lang_total') }}
          </span>

              <h3 class="font-weight-bolder mx-auto text-color" width="100%">
                {{ totalWithVoucher | currency }}
              </h3>
            </v-col>


            <v-col class="bg-white pr-2 pl-2 pt-0" cols="12" style="width: 110% !important;">
              <v-row align="center" no-gutters>
                <v-col cols="4">
                  <v-list-item-content>
                    <v-list-item-subtitle class="text-muted">
                      {{ $t('generic.lang_receipt_gegeben') }}
                    </v-list-item-subtitle>
                    <v-list-item-title class="font-weight-bolder pt-1 font-size-xlg">
                <span class="text-color">
                  {{ moneyGiven | currency }}
                </span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-col>

                <v-col cols="3">
                  <v-list-item-content>
                    <v-list-item-subtitle class="text-muted">
                      {{ $t('generic.lang_back') }}
                    </v-list-item-subtitle>
                    <v-list-item-title class="font-weight-bolder pt-1 font-size-xlg">
                <span class="text-color">
                  {{ moneyBack  | currency }}
                </span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-col>

                <v-col class="text-right" cols="5" v-if="false">
                  <v-btn @click="showTippDialog = true" class="bg-white pr-1 pl-1" rounded small>
                    <span class="text-muted">
                      {{ $t('generic.lang_tip') }}
                    </span>
                    <span class="rounded-circle ml-1 pa-1" v-bind:style="{'background-color': '#FF6969'}">
                      <font-awesome-icon :icon="['fal', 'heart']" v-bind:style="{color: 'white'}"/>
                    </span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>

            <v-col class="pa-6" cols="12">
              <v-card class="rounded pa-2" elevation="5" style="border-radius: 15px !important;">
                <v-card-text class="pa-0 pt-2">
                  <v-form ref="form" lazy-validation>
                    <v-text-field v-model="email" :color="'#FF6969'" :disabled="sendLoader"
                                  :label="this.$t('generic.lang_emailAdresse')"
                                  :loading="sendLoader"
                                  height="50">
                      <template v-slot:prepend-inner>
                        <font-awesome-icon
                            :icon="['fal', 'user']"
                            class="text-muted ml-2 mt-1"
                            style="font-size: 28px"
                        />
                      </template>

                      <template v-slot:append>
                        <font-awesome-icon
                            :icon="['fal', 'envelope-open']"
                            class="text-white mr-2 mt-1 rounded-circle"
                            style="background-color: grey; font-size: 30px; padding: 6px !important;"
                            @click="send"
                        />
                      </template>
                    </v-text-field>

                    <br/>
                    <br/>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col class="text-center" cols="4">
              <v-btn class="w-75 br-tr btn-transition btn text-muted bg-transparent border rounded-lg"
                     style="height: 100px !important;"
                     @click="closeDialog()">
                <div class="widget-content">
                  <div class="btn-icon-wrapper">
                    <font-awesome-icon :icon="['fal', 'home']" size="3x"/>
                  </div>
                  <div class="widget-heading fsize-1 pt-1 text-wrap">
                    {{ $t('customers.lang_bill_nextCust') }}
                  </div>
                </div>
              </v-btn>
            </v-col>
            <v-col class="text-center" cols="4">
              <v-btn class="w-75 br-tr btn-transition btn text-muted bg-transparent border rounded-lg"
                     style="height: 100px !important;" :loading="loadingPrinting" :disabled="loadingPrinting" @click="reprintInvoice">
                <div class="widget-content">
                  <div class="btn-icon-wrapper">
                    <font-awesome-icon :icon="['fal', 'print']" size="3x"/>
                  </div>
                  <div class="widget-heading fsize-1 pt-1 text-wrap">
                    {{ $t('generic.lang_reprint') }}
                  </div>
                </div>
              </v-btn>
            </v-col>
            <v-col class="text-center" cols="4">
              <v-btn class="w-75 br-tr btn-transition btn text-muted bg-transparent border rounded-lg"
                     style="height:100px !important;" :loading="loadingDownloadInvoice"
                     @click="downloadInvoicePDF()">
                <div class="widget-content">
                  <div class="btn-icon-wrapper">
                    <font-awesome-icon :icon="['fal', 'download']" size="3x"/>
                  </div>
                  <div class="widget-heading fsize-1 pt-1 text-wrap">{{ $t('generic.lang_download') }} A4</div>
                </div>

              </v-btn>
            </v-col>
            <!--
                  <v-col class="pa-2" cols="12">
                    <v-row align="center" no-gutters>
                      <v-col v-for="(print , i) in this.printer.printers" :key="i" class="text-center pa-1" cols="6">
                        <b-button class="ma-auto text-muted rounded bg-white pa-2 border-0"
                                  height="90px" style="max-width: 100% !important; min-width: 100% !important;">
                          <font-awesome-icon
                              :icon="['fal', 'print']"
                              class="btn-icon-wrapper ma-auto btn-icon-lg"
                              size="4x"
                              v-bind:style="{
                            color: '#FF6969'
                              }"
                          />
                          <br/>
                          <span class="icons_text">
                            {{ print.name }}
                          </span>
                        </b-button>
                      </v-col>
                    </v-row>
                  </v-col>-->
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <div v-if="iframePDFContent.length > 0 && showPdfViewer" style="height: 700px; width: 500px;position:absolute;margin: auto;left: 0; right: 0; top: 0; bottom: 0;" >
      <v-btn style="position:absolute;margin: auto;top: -16px; right: -16px;" @click="showPdfViewer = false" color="warning" fab x-small>
        <v-icon>close</v-icon>
      </v-btn>
      <iframe :src="iframePDFContent" height="700" id="orderPDF" :name="'Rechnung_'+invoiceResultData.invoiceID" width="500"/>
    </div>
    <AfterSaleDialogTipp :show-dialog="showTippDialog" :invoiceData="invoiceResultData" @closeDialog="closedTippDialog"></AfterSaleDialogTipp>
  </v-dialog>
</template>

<script>


import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import {
  faCreditCard,
  faCreditCardBlank,
  faEnvelopeOpen,
  faEuroSign,
  faHandsHeart,
  faHeart,
  faLineColumns,
  faPiggyBank,
  faPrint,
  faReceipt,
  faUser,
  faDownload
} from '@fortawesome/pro-light-svg-icons'
import {mapGetters, mapState} from "vuex";
import FileSaver from 'file-saver';
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import {createInvoicePrintingData} from "@/plugins/printing/printInvoice";
import {createRetourePrintingData} from "@/plugins/printing/printRetoure";
import moment from "moment";
import AfterSaleDialogTipp from "@/components/pos/AfterSaleDialogTipp";
import {roundNumber} from "@/plugins/round";
import {format} from "mathjs";
import {printDataFromPrinter} from "../../plugins/printing/printerController";


library.add(faPrint, faHeart, faReceipt,
    faCreditCard,
    faCreditCardBlank,
    faHandsHeart,
    faEuroSign,
    faPiggyBank,
    faLineColumns,
    faUser,
    faEnvelopeOpen,
    faDownload
)

export default {
  name: "AfterSaleDialog",

  components: {
    AfterSaleDialogTipp,
    'font-awesome-icon': FontAwesomeIcon,
  },

  props: {
    posType: {
      type: String,
      required: true
    },
    invoiceResultData: Object,
    invoiceXMLPrintData: Array,
    invoiceItemsOverwrite: {
      type: Array,
      default: () => {
        return [];
      }
    },
    newOrderArray: {
      type: Array,
      default: () => {
        return [];
      }
    },
    showDialog: Boolean,
    total: Number,
    totalWithVoucher: Number,
    moneyGiven: Number,
    moneyBack: Number,
    goodsValue: Number,
    vouchersValue: Number,
    realUser: Object,
    split:{
      type:Boolean,
      default:false,
    }
  },
  data() {
    return {
      email: "",
      sendLoader: false,
      loadingDownloadInvoice: false,
      loadingPrinting: false,
      loading: false,
      errorDialogLoading: false,
      errorDialogShowCancelButton: false,
      errorDialogMessage: false,
      errorDialogCloseAction: false,
      errorDialogConfirmButtonText: false,
      errorDialog: false,
      showPdfViewer: false,
      iframePDFContent: '',
      countDown: 0,
      timer: 0,
      showTippDialog: false
    }
  },
  watch: {
    showDialog(val) {
      if (val) {
        if (parseInt(Number(this.settings.settings.ReturnToPosAfter)) > 0) {
          this.countDown = parseInt(Number(this.settings.settings.ReturnToPosAfter));
          this.startCountDown();
        }
        if (parseInt(Number(this.settings.settings.downloadInvoiceA4Automatically)) > 0) {
          this.downloadInvoicePDF();
        }
        if (parseInt(Number(this.settings.settings.printInvoiceA4Automatically)) > 0) {
          this.downloadInvoicePDF(true, false);
        }
      } else {
        if (parseInt(Number(this.settings.settings.ReturnToPosAfter)) > 0) {
          clearInterval(this.timer);
        }
      }

      if (this.customer)
        this.email = this.customer.email;
    }
  },
  computed: {
    ...mapState([
      'pos',
      'api',
      'tse',
      'cashierIDs',
      'settings',
      'posLayoutTemplates',
      'printer',
      'payments',
      'user',
      'deposit'
    ]),
    ...mapState('printer', {
      printers: state => state.printers
    }),
    ...mapGetters({
      retailGoodsValue: 'pos/retail/goodsValue',
      overrideInvoicePrinterId: 'pos/gastro/overrideInvoicePrinterId',
      fiscalClient: 'tse/fiscalClient',
      activeUser: 'multiUser/activeUser',
    }),
    invoicePrinter() {

      if(this.posType === "gastro" && this.overrideInvoicePrinterId){
        return this.printers.find((printer) => {
          return this.overrideInvoicePrinterId === printer.id && printer.type === 1;
        });
      }

      return this.printers.find((printer) => {
        if (printer.cashierID.includes(this.api.auth.cashierID)) {
          if (printer.type === 1) {
            return true;
          }
        }
      });
    },
    customer() {
      if (this.posType === "gastro") {
        return this.pos.gastro.customer;
      }

      if (this.posType === "retail") {
        return this.pos.retail.customer;
      }
      
      if (this.posType === "wholesaleRetail") {
        return this.pos.wholesaleRetail.customer;
      }

      return null;
    },
    invoiceItemsToPrint() {
      let items = this._.cloneDeep(this.invoiceItems);

      items.forEach((invoiceItem) => {
        //CHECK IF WE HAVE CHOOSEN TAKE AWAY
        if (this.pos.gastro.takeAway > 0) {
          if (invoiceItem.takeAwayTaxing > 0) {
            invoiceItem.taxValue = this.offSiteTax;
          }
        }

        //DISCOUNT
        if (invoiceItem.discount > 0) {
          //invoiceItem.sellPrice = parseFloat((((100 - invoiceItem.discount) / 100) * invoiceItem.sellPrice).toFixed(2));
          invoiceItem.sellPrice = parseFloat(((((invoiceItem.sellPrice * ((100 - invoiceItem.discount) / 100)) + Number.EPSILON) * 100) / 100));
          invoiceItem.sellPrice = format(sellPrice, {precision: 14});
          invoiceItem.sellPrice = roundNumber(sellPrice,2);
        }
      });

      return items;
    },
    invoiceItems() {
      let invoiceItems = [];

      if (this.posType === "retail") {
        invoiceItems = this.pos.retail.orderedItems;
      }
      
      if (this.posType === "wholesaleRetail") {
        invoiceItems = this.pos.wholesaleRetail.orderedItems;
      }

      if (this.posType === "gastro") {
        //USE OVERWRITE ARRAY -> WHEN WE SPLIT PAY TABLE IN GASTRO POS, OUR INVOICEITEMS ARE PASSING WITH invoiceItemsOverwrite(PROP)
        if (this.invoiceItemsOverwrite.length > 0) {
          invoiceItems = this.invoiceItemsOverwrite;
        } else {
          if (!this.pos.gastro.hasOwnProperty("table"))
            return invoiceItems;

          if (!this.pos.gastro.table.hasOwnProperty("name"))
            return invoiceItems;

          if (!this.pos.gastro.hasOwnProperty("party"))
            return invoiceItems;

          if (!this.pos.gastro.party)
            return invoiceItems;

          if (!this.pos.gastro.party.hasOwnProperty("name"))
            return invoiceItems;

          if (this.pos.gastro.table.name !== 0) {
            if (this.pos.gastro.orderedItems.hasOwnProperty(this.pos.gastro.party.name)) {
              if (this.pos.gastro.orderedItems[this.pos.gastro.party.name].length > 0) {
                invoiceItems = this.pos.gastro.orderedItems[this.pos.gastro.party.name];
              }
            }

          } else {
            if (this.pos.gastro.openItems.hasOwnProperty(this.pos.gastro.party.name)) {
              if (this.pos.gastro.openItems[this.pos.gastro.party.name].length > 0) {
                invoiceItems = this.pos.gastro.openItems[this.pos.gastro.party.name];
              }
            }
          }
        }
      }

      return invoiceItems.map((invoiceItem) => {
        //CONVERT VOIDED
        // invoiceItem.isVoid = invoiceItem.isVoid ? 1 : 0;

        return invoiceItem;
      });
    },
  },
  methods: {
    send() {
      if (!this.$refs.form.validate()) return;
      this.sendLoader = true;

      let data = {};
      data.email = this.email;
      data.invoiceID = this.invoiceResultData.invoiceID;
      data.customer = this.customer;
      data.invoiceUUID = this.invoiceResultData.invoiceUUID;
      this.axios.post(ENDPOINTS.EMAILS.AFTERSALE, data).then((res) => {
        if (res.data.STATUS === "SUCCESS") {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_success'),
            color: "success"
          });

          //  this.$refs.form.reset();
        } else if (res.data.STATUS === "FAILED") {
          Events.$emit("showSnackbar", {
            message: res.data.msg,
            color: "error"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.sendLoader = false;
      })
    },
    closedTippDialog() {
      this.showTippDialog = false;
      this.closeDialog();
    },
    closeDialog() {
      if (this.posType === 'gastro') {
        if(!this.split)
        if (this.pos.gastro.table.name === 0) {

          /*this.$router.push({
            name: "pos.gastro.tresenverkauf"
          });*/
          //console.log("check");
          Events.$emit('resetGrid');
        } else {
          if(this.pos.gastro.isDeliveryService){
            this.$router.replace({
              name: "pos.delivery"
            });
          }else{
            this.$router.replace({
              name: "pos.gastro.tables",
            });
          }
        }
      } else {
        Events.$emit('resetGrid');
      }

      clearInterval(this.timer);

      this.$emit("closeDialog");
      this.$emit("nextCustomer");
    },
    downloadInvoicePDF(canPrint = false, canDownload = true) {
      this.loadingDownloadInvoice = true;
      this.axios.post(ENDPOINTS.ACCOUNTING.DISPLAYINVOICE.PRINTA4, {
            invoiceID: this.invoiceResultData.invoiceUUID
          },
          {
            responseType: 'arraybuffer',
          }).then((res) => {


        //GENERATE PDF CONTENT FOR iFRAME
        const blobContent = new Blob([res.data], {type: "application/pdf"});
        this.iframePDFContent = URL.createObjectURL(blobContent);

        if(canPrint){
          this.showPdfViewer = true;
          setTimeout(()=>{
            if (document.getElementById("orderPDF")) {
              var frm = document.getElementById("orderPDF").contentWindow;
              frm.focus();// focus on contentWindow is needed on some ie versions
              frm.print();
            }
          },500);
        }

        if(canDownload){
          //DOWNLOAD INVOICE AS PDF
          FileSaver.saveAs(new Blob([res.data], {
            type: "application/pdf"
          }), "Rechnung" + this.invoiceNoReal + ".pdf");
        }
      }).catch((err) => {
        console.log(err);
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => {
        this.loadingDownloadInvoice = false;
      })
    },
    reprintInvoice() {
      //CHECK IF INVOICE PRINTER EXISTS
      if (!this.invoicePrinter) {
        //this.checkVoucher();
        return;
      }

      if(this.invoiceXMLPrintData.length === 0)
        return;

      //RESET INVOICE ERROR MESSAGE
      //this.errorInvoicePrintDialogMessage = "";

      this.loadingPrinting = true;
      //remove cash drawer element when reprinting invoice
      let printObj = JSON.parse(JSON.stringify(this.invoiceXMLPrintData.filter(el=>!el.hasOwnProperty("cashDrawer"))));
      /*
      //GENERATE INVOICE DATA
      let data = {
        saleID: this.invoiceResultData.invoiceID,
        cashierID: this.invoiceResultData.cashierID,
        userID: this.invoiceResultData.userID,
        ts: this.invoiceResultData.ts,
        moneyGiven: this.invoiceResultData.moneyGiven,
        paymentType: this.invoiceResultData.paymentType
      };

      if (this.posType === "gastro") {
        data["tableNo"] = this.pos.gastro.table.name;
      }

      if (this.posType === "retail") {
        data["tableNo"] = "";
      }

      //TRY TO CREATE PRINT DATA
      let printData;

      try {
        printData = createInvoicePrintingData(this.invoiceItemsToPrint, data, this.invoicePrinter.options, this.realUser, false, this.goodsValue, this.vouchersValue);

        //BEWIRTUNGSBELEG
        if (this.bewirtungsbeleg) {
          let today = new Date();
          let current_date = today.getDate() + "." + (today.getMonth() + 1) + "." + today.getFullYear();
          let current_city = "";

          if (this.settings.settings.hasOwnProperty("systemCity"))
            current_city = this.settings.settings.systemCity;

          printData.push({
            text: "\nAngaben zum Nachweis der Hoehe und der \nbetrieblichen Veranlassung von \nBewirtungsaufwendungen Par. 4 Abs. 5\nTag der Bewirtung:" + current_date + "\nBewirtete Personen:\n\n\n\n\n\n\n\nAnlass der Bewirtung:\n\n\n\n\nDatum / Ort:" + current_date + " / " + current_city + "\nUnterschrift:\n\n\n\n"
          });
        }

        //CHECK IF LAST ELEMENT IS CUT
        let lastElememt = printData[(printData.length - 1)];

        if (!lastElememt.hasOwnProperty("cut"))
          printData.push({cut: true});
      } catch (err) {
        this.loadingPrinting = false;

        this.showErrorDialog('Kassenbeleg konnte nicht gedruckt werden', false, "check_vouchers");

        return;
      }
      */

      //ADDING SCALE TEXT STANDARD
      if(this.$store.getters['scale/isEnabled']){
        printObj.unshift({
          text:(this.$i18n.locale==='de'?' - Kopie - ':' - Copy - '),
        })
        printObj.unshift({
          align:'center',
        })
        printObj.unshift({
          fontSize:2,
        })
      }
      printDataFromPrinter(this.invoicePrinter,printObj).then(()=>{

      }).catch((err)=>{
        this.showErrorDialog(this.$t('generic.lang_printReceiptError'), false, "check_vouchers_tickets");
      }).finally(()=>{
        this.loadingPrinting = false;
      })

    },
    showErrorDialog(message, showCancelButton = false, closeAction = null, confirmButtonText = null) {
      this.errorDialogLoading = false;
      this.errorDialogShowCancelButton = false;

      //CHECK IF ARE ON MOBILE POS AND SHOULD SHOW THE BOOTSTRAP TOAST
      if (closeAction === "close_only") {
        this.$bvToast.toast(message, {
          title: 'error',
          autoHideDelay: 4000,
          variant: 'danger'
        });

        return;
      }

      this.errorDialogMessage = message;
      this.errorDialogShowCancelButton = showCancelButton;

      if (closeAction !== null)
        this.errorDialogCloseAction = closeAction;
      else
        this.errorDialogCloseAction = "";

      if (confirmButtonText !== null)
        this.errorDialogConfirmButtonText = confirmButtonText;
      else
        this.errorDialogConfirmButtonText = this.$t('generic.lang_next');

      //OPEN ERROR DIALOG
      this.errorDialog = true;
    },
    startCountDown() {
      this.timer = setInterval(() => {
        // CHECK IF TIPP DIALOG IS OPEN
        // THEN SKIP
        if(this.showTippDialog)
          return;

        if (this.countDown > 0) {
          this.countDown--;
        } else {
          this.closeDialog();
        }
      }, 1000);
    },
    // Stop the countdown timer
    stopCountDown() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    // Continue the countdown timer
    continueCountDown() {
      if (!this.timer) {
        this.startCountDown();
      }
    }

  },

  created() {
    if (this.customer)
      this.email = this.customer.email;
  }
}
</script>

<style scoped>
.text-color {
  color: #515C6F !important;
}

.icons_text {
  font-size: 10px !important;
}
</style>